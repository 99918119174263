import React, { useEffect } from 'react';
import './HomePage.scss';
import { useLocation } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';
import Detay from '../../components/Detay/Detay';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../utils/utils';
import Footer from '../../components/Footer/Footer';
import Learn from '../../components/Learn/Learn';
import Simulator from '../../components/Simulator/Simulator';
import ComingSoon from '../../components/ComingSoon/ComingSoon';
import Investment from '../../components/Investment/Investment';

const HomePage = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('HomePage is loaded');
        return () => {
            console.log('HomePage is unloaded');
        };
    }, [location]);

    return (
        <motion.div
            transition={pageTransitions}
            variants={pageVariants}
            animate="enter"
            exit="exit"
            initial="initial"
            className="HomePage"
            data-testid="HomePage">
            <div className="relative bg-white">
                <div className="flex w-full flex-col">
                    <Hero />
                    <Detay />
                    <ComingSoon />
                    <Footer label="blue" />
                </div>
            </div>
        </motion.div>
    );
};

export default HomePage;
