import React, { useEffect } from 'react';
import './DownloadPage.scss';
import { useLocation } from 'react-router-dom';
import Download from '../../assets/png/download-visual3.png';
import Qr from '../../assets/png/qrcode_elefant.png';
import { setText, setTitle } from './utils';

import useWindowSize from '../../hooks/useWindowSize';
import DirectPage from '../DirectPage/DirectPage';
import Footer from '../../components/Footer/Footer';
import { useLanguage } from '../../store/store';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../utils/utils';
const DownloadPage = () => {
    const location = useLocation();
    const { width } = useWindowSize();
    const { selectedLanguage } = useLanguage();

    useEffect(() => {
        console.log('DownloadPage is loaded');
        return () => {
            console.log('DownloadPage is unloaded');
        };
    }, [location]);

    return (
        <motion.div
            transition={pageTransitions}
            variants={pageVariants}
            animate="enter"
            exit="exit"
            initial="initial"
            className="DownloadPage"
            data-testid="DownloadPage">
            {width > 0 && width < 1024 && <DirectPage></DirectPage>}
            {width >= 1024 && (
                <div className="DownloadPage__frame">
                    <div className="DownloadPage__frame__content">
                        {setTitle(selectedLanguage)}
                        <div className="DownloadPage__frame__content__links">
                            <div className="DownloadPage__frame__content__links__qr">
                                <img src={Qr} alt="QrKod" />
                            </div>
                            <div className="DownloadPage__frame__content__links__store">
                                {setText(selectedLanguage)}
                            </div>
                        </div>
                    </div>
                    <div className="DownloadPage__frame__visual">
                        <img loading="lazy" width={650} height={630} src={Download} alt="" />
                    </div>
                </div>
            )}
            {width >= 1024 && <Footer label="blue"></Footer>}
        </motion.div>
    );
};

export default DownloadPage;
