import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';

import './App.css';
import Navigation from './components/Navigation/Navigation';
import AboutPage from './pages/AboutPage/AboutPage';
import AcademyPage from './pages/AcademyPage/AcademyPage';
import DownloadPage from './pages/DownloadPage/DownloadPage';
import HomePage from './pages/HomePage/HomePage';
import MarketPage from './pages/MarketPage/MarketPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import TradePage from './pages/TradePage/TradePage';
import WelcomePage from './pages/WelcomePage/WelcomePage';

const AnimatedSwitch = () => {
    const location = useLocation();
    return (
        <AnimatePresence initial={false} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={HomePage}></Route>
                <Route path="/register" component={RegisterPage}></Route>
                <Route path="/academy" component={AcademyPage}></Route>
                <Route path="/markets" component={MarketPage}></Route>{' '}
                <Route path="/trade" component={TradePage}></Route>
                <Route exact path="/welcome" component={WelcomePage}></Route>
                <Route exact path="/download" component={DownloadPage}></Route>
                <Route exact path="/about" component={AboutPage}></Route>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
            </Switch>
        </AnimatePresence>
    );
};

function App() {
    return (
        <Router>
            <Navigation></Navigation>
            <AnimatedSwitch></AnimatedSwitch>
        </Router>
    );
}

export default App;
