import React from 'react';

export const setText = (lang: string) => {
    switch (lang) {
        case 'en':
            return <p>You are directed to the app store compatible with your phone.</p>;
        case 'tr':
            return <p>Telefonunuzla uyumlu uygulama dükkanına yönlendiriliyorsunuz. </p>;
        case 'de':
            return <p>Sie werden zum entsprechenden App Store weitergeleitet.</p>;
        default:
            break;
    }
};
