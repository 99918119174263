import React from 'react';

export const setStickerText = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'COMING IN MAY 2022';
        case 'de':
            return 'IN MAI 2022';
        case 'tr':
            return 'MAYIS 2022';
        default:
            return 'COMING IN MAY 2022';
    }
};
export const setTitle = (lang: string) => {
    switch (lang) {
        case 'en':
            return <h3>Cross-Blockchain Portfolio</h3>;
        case 'tr':
            return <h3>Cross-Blockchain Portföyünüz</h3>;
        case 'de':
            return <h3>Cross-Blockchain-Portfolio</h3>;

        default:
            return <h3>Cross-Blockchain Portfolio</h3>;
    }
};
export const setParag = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <article>
                    <p>
                        New generation blockchains like Terra, Avalanche and Ethereum enable you to
                        invest both in Cryptocurrencies and synthetic versions of traditional assets
                        like Stocks and Metals.
                    </p>
                    <p>
                        With Elefant you can aggregate them all under a single Portfolio view and
                        execute Swap, Bridge and Stake operations.
                    </p>
                </article>
            );
        case 'de':
            return (
                <article>
                    <p>
                        Blockchains wie Terra, Avalanche and Ethereum ermöglichen Investition in
                        beide, Kriptowaehrungen und auch in synthetische Versionen von Aktien und
                        wertvolle Metalle.
                    </p>
                    <p>
                        Mit Elefant können Sie all Ihre digitale Anlagen unter einem Portfolio
                        darstellen und cross-blockchain Swap, Bridge und Stake Operationen
                        durchführen.
                    </p>
                </article>
            );
        case 'tr':
            return (
                <article>
                    <p>
                        Terra, Avalanche ve Ethereum gibi yeni nesil blokzincirler kripto paraların
                        yanısıra hisse senedi ve değerli metallerin sentetik versiyonlarına da
                        yatırım yapma olanağı sağlamaktadır.
                    </p>
                    <p>
                        Elefant ile farklı blokzincirler üzerinde sahip olduğunuz tüm dijital
                        varlıkları tek bir portföy altında görüntüleyebilir, blokzincirler arası
                        Swap, Bridge ve Stake işlemleri gerçekleştirebilirsiniz.{' '}
                    </p>
                </article>
            );
        default:
            return (
                <article>
                    <p>
                        New generation blockchains like Terra, Avalanche and Ethereum enable you to
                        invest both in Cryptocurrencies and synthetic versions of traditional assets
                        like Stocks and Metals.
                    </p>
                    <p>
                        With Elefant you can aggregate them all under a single Portfolio view and
                        execute Swap, Bridge and Stake operations.
                    </p>
                </article>
            );
    }
};
