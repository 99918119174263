import React, { useState } from 'react';
import { useLanguage } from '../../store/store';
import Arrow from '../../assets/svg/arrow.svg';
import './LanguageSwitch.scss';
import { AnimatePresence } from 'framer-motion';

const LanguageSwitch = () => {
    const { selectedLanguage, setLanguage } = useLanguage();
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    return (
        <div className="LanguageSwitch" data-testid="LanguageSwitch">
            <div onClick={() => setIsListOpen(!isListOpen)} className="LanguageSwitch__main">
                <span>{selectedLanguage.toUpperCase()}</span>
                <span>
                    <img className={isListOpen ? 'arrow open' : 'arrow'} src={Arrow} alt="arrow" />
                </span>
                <AnimatePresence>
                    {isListOpen && (
                        <div className="LanguageSwitch__list">
                            <span
                                onClick={() => {
                                    setLanguage('en');
                                    setIsListOpen(false);
                                }}>
                                EN
                            </span>
                            <span
                                onClick={() => {
                                    setLanguage('de');
                                    setIsListOpen(false);
                                }}>
                                DE
                            </span>
                            <span
                                onClick={() => {
                                    setLanguage('tr');
                                    setIsListOpen(false);
                                }}>
                                TR
                            </span>
                        </div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default LanguageSwitch;
