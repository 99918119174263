import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../utils/utils';
import Brand from '../../assets/svg/brand.svg';
import Cloud1 from '../../assets/png/cloud1.png';
import Cloud2 from '../../assets/png/cloud2.png';
import { setBrandText, setHead, setList, setTitle, subTitle } from '../AboutPage/utils';
import { useLanguage } from '../../store/store';

const PrivacyPolicy = () => {
    const location = useLocation();
    const { selectedLanguage } = useLanguage();

    useEffect(() => {
        console.log('PrivacyPolicy is loaded');
        return () => {
            console.log('PrivacyPolicy is unloaded');
        };
    }, [location]);

    return (
        <motion.div
            transition={pageTransitions}
            variants={pageVariants}
            animate="enter"
            exit="exit"
            initial="initial"
            className="PrivacyPolicy"
            data-testid="PrivacyPolicy">
            <div className="PrivacyPolicy__frame">
                <div className="PrivacyPolicy__frame__main">
                    <div className="PrivacyPolicy__frame__main__title">Privacy Policy</div>
                    <div className="PrivacyPolicy__frame__main__parag">
                        This Privacy Policy (“Privacy Policy”) explains privacy policies of Wunder
                        Innovation Studio Tasarım ve İletişim Danışmanlığı A.Ş. (Elefant) concerning
                        the data shared by its users. The Privacy Policy is prepared to give
                        information about the data collected, collection method, to whom such data
                        may be shared with and the rights of the users, as well as cookies. This
                        document explains Elefant Mobile App’s privacy policy concerning the
                        personal data of its users.
                    </div>
                    <div className="PrivacyPolicy__frame__main__list">
                        <div className="PrivacyPolicy__frame__main__list__head">
                            1. Requested Data
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__head">
                            We collect the following information for your use of the application;
                        </div>
                        <ul className="PrivacyPolicy__frame__main__list__con">
                            <li>
                                <span className="circle"></span>
                                <span>Version of the operating system of your mobile device.</span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Location data obtained through your mobile device. (The
                                    application collects your location information while it is open
                                    and, on the screen, or while it is open and not on the screen,
                                    depending on your preferred device settings.).
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Device information: Information such as the hardware model,
                                    operating system and its versions, unique device and advertising
                                    identifiers, IP address, mobile network information and language
                                    preferences.
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Information about your interaction with the application. For
                                    example; application features or displayed screens and links
                                    used to interact with the application, access time, system
                                    failures and other system operations.
                                </span>
                            </li>
                        </ul>
                        <div className="PrivacyPolicy__frame__main__list__head">
                            1.1 Intended Use
                        </div>
                        <ul className="PrivacyPolicy__frame__main__list__con">
                            <li>
                                <span className="circle"></span>
                                <span>Informing you about the service updates and errors.</span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>Informing you about campaigns.</span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Conducting research and gathering statistics regarding customer
                                    habits.
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Communication with our customers and managing customer
                                    relations.
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    To conduct analyzes to improve our products and services,
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>To carry out activities for customer satisfaction,</span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Promoting products and services offered by Elefant; carrying out
                                    marketing activities,
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    To receive and evaluate the feedbacks regarding the improvement
                                    of our products / services,
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>To follow up and finalize requests / complaints,</span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    To carry out our activities in accordance with the legislation,
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>Review and audit of our business activities,</span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Carrying out financial and accounting affairs related to
                                    payments.
                                </span>
                            </li>
                        </ul>
                        <div className="PrivacyPolicy__frame__main__list__head">
                            1.2 Data Sharing
                        </div>
                        <ul className="PrivacyPolicy__frame__main__list__con">
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Elefant does not share any personal data, traffic data or any
                                    content of communication without your explicit consent, unless
                                    required to do so under the relevant law or requested by
                                    competent authorities.
                                </span>
                            </li>
                            <li>
                                <span className="circle"></span>
                                <span>
                                    Elefant may disclose personal data to fulfill legal requirements
                                    asked by competent authorities in order to act in compliance
                                    with its legal rights or to defend itself against legal claims,
                                    to preserve Elefant’ interests, fight against fraud, enforce its
                                    principles or to ensure protection of rights, property or
                                    security of any person.
                                </span>
                            </li>
                        </ul>
                        <div className="PrivacyPolicy__frame__main__list__head">
                            2. Data Storage
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__con">
                            <span>
                                Your data is stored in our secure servers. Some of Elefant’ servers
                                are located outside of Turkey. By agreeing to the User Agreement,
                                you are giving explicit consent for storage of your data at servers
                                located outside of Turkey. If data is transferred over the publicly
                                accessible internet connection, 100% security of the data cannot be
                                guaranteed. We use a secure connection in order to protect your
                                data, but you are responsible for the cyber-attacks against your
                                Internet connection during data transfer. Any security precaution
                                will be taken by our side to protect your data upon reaching out to
                                our servers.
                            </span>
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__head">
                            3. Access to Data and Updates
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__con">
                            <span>
                                If you request, we can share a copy of your personal data stored in
                                our system once the necessary security protocols have been cleared.
                                You can update some of your information through the application.
                            </span>
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__head">
                            4. Cookies and Information Stored on Your Device
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__con">
                            <span>
                                In order for you to use our application in a quick and efficient
                                manner, we store cookies on your device. You can disable your device
                                from storing cookies through your device’s settings. But by doing
                                so, you may not be able to use all of the features of the
                                application.
                            </span>
                        </div>
                        <div className="PrivacyPolicy__frame__main__list__head">5. Changes</div>
                        <div className="PrivacyPolicy__frame__main__list__con">
                            <span>
                                We may make changes on this Privacy Policy at any given time and
                                inform you about such changes through the application. This Privacy
                                Policy was last updated on March 17, 2022.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="PrivacyPolicy__frame__brand">
                    <div className="PrivacyPolicy__frame__brand__logo">
                        <img src={Brand} alt="wunder"></img>
                    </div>
                    <div className="PrivacyPolicy__frame__brand__text">
                        {setBrandText(selectedLanguage)}
                    </div>
                </div>
                <img className="cloud1" src={Cloud1} alt="" />
                <img className="cloud2" src={Cloud2} alt="" />
            </div>
            <Footer label="blue"></Footer>
        </motion.div>
    );
};

export default PrivacyPolicy;
