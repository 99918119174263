import React from 'react';

export type Tlist = {
    en: string[];
    de: string[];
    tr: string[];
};
export const list: Tlist = {
    en: [
        'Investing should be available to everyone regardless of their citizenship and financial status. ',
        'Markets shut down, but the Internet does not. Therefore, the services should be accessible on 7/24. ',
        'Traditional (gold, stocks) and new generation investing instruments (Bitcoin, NFTs, Staking) should be available under one roof.',
        'Investing terminology should be simplified and made understandable for everyone. '
    ],
    de: [
        'Investieren sollte für jeden möglich sein, unabhängig von der Staatsbürgerschaft und dem finanziellen Status.',
        'Die Handelsmarkt schliesst jeden Tag, aber das Internet nicht. Daher sollten die Dienste von Elefant 7/24 zugänglich sein.',
        'Traditionelle Anlageinstrumente (wie Gold, Aktien) und die neuen (wie Crypto, NFTs) sollten in dem selben App verfügbar sein.',
        'Investment-Terminologie sollte vereinfacht und für alle verständlich gemacht werden.'
    ],
    tr: [
        'Yatırım servisleri kişilerin finansal güçleri ve vatandaşı oldukları ülkelerden bağımsız, herkes için erişilebilir olmalıdır.',
        'Geleneksel borsalar gün sonunda işleme kapanır, fakat hayat yaşanmaya ve internet çalışmaya devam etmektedir. ',
        'Modern yatırım servisleri 7/24 işleme açık olmalıdır.Altın, Hisse Senedi vb. geleneksel yatırım araçları ile Crypto, NFT gibi yeni nesil yatırım araçları aynı servis çatısı altından erişilebilir olmalıdır',
        'Yatırım terminolojisi, kuralları ve işleyişi herkes için anlaşılır bir dil ile ifade edilmelidir. '
    ]
};

export const setTitle = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'About';
        case 'de':
            return 'Über Uns';
        case 'tr':
            return 'Hakkımızda';
        default:
            return 'About';
    }
};
export const subTitle = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <p>
                    We started Elefant to enable
                    <span className="text-blue1"> Wealth Building For Everyone.</span>{' '}
                </p>
            );
        case 'tr':
            return (
                <p>
                    Elefant,
                    <span className="text-blue1"> herkesin yatırım yapabilmesini sağlamak </span>
                    amacıyla hayata geçirildi.
                </p>
            );
        case 'de':
            return <p>Elefant wurde gegründet, um Vermögensaufbau für jeden zu ermöglichen.</p>;
        default:
            return (
                <p>
                    We started Elefant to enable
                    <span className="text-blue1"> Wealth Building For Everyone.</span>{' '}
                </p>
            );
    }
};
export const setHead = (lang: string) => {
    switch (lang) {
        case 'en':
            return <p>Along the way, we are leveraging four principles:</p>;
        case 'tr':
            return (
                <p>
                    Bu amaca ulaşabilmek için, çalışmalarımızda aşağıdaki 4 prensibi temel alıyoruz:
                </p>
            );
        case 'de':
            return <p>Dabei verlassen wir uns auf die folgenden vier Prinzipien:</p>;
        default:
            return <p>Along the way, we are leveraging four principles:</p>;
    }
};
export const setList = (lang: string): string[] => {
    switch (lang) {
        case 'en':
            return list.en;
        case 'de':
            return list.de;
        case 'tr':
            return list.tr;
        default:
            return list.en;
    }
};
export const setBrandText = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div className="mt-4">
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Elefant is designed and developed by{' '}
                        <span className="font-bold">Wunder Innovation Studio. </span>
                    </p>
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        For more information please visit{' '}
                        <a className="text-blue1" href="https://wunder.is">
                            https://wunder.is
                        </a>
                    </p>
                </div>
            );
        case 'de':
            return (
                <div className="mt-4">
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Elefant wurde von{' '}
                        <span className="font-bold">Wunder Innovation Studio</span> entworfen und
                        entwickelt.
                    </p>
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Weitere Informationen finden Sie unter{' '}
                        <a href="https://wunder.is" className="text-blue1">
                            https://wunder.is
                        </a>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div className="mt-4">
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Elefant <span className="font-bold">Wunder Innovation Studio</span>{' '}
                        tarafından tasarlanmış ve geliştirilmiştir.
                    </p>
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Daha fazla bilgi için
                        <a className="text-blue1" href=" https://wunder.is">
                            {' '}
                            https://wunder.is
                        </a>{' '}
                        adresini ziyaret edebilirsiniz.{' '}
                    </p>
                </div>
            );
        default:
            break;
    }
};
