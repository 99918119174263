import React, { useEffect } from 'react';
import './MarketPage.scss';
import { useLocation } from 'react-router-dom';

const MarketPage = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('MarketPage is loaded');
        return () => {
            console.log('MarketPage is unloaded');
        };
    }, [location]);

    return (
        <div className="MarketPage" data-testid="MarketPage">
            <div className="relative bg-white">
                <div className="">
                    <h1>MarketPage Page</h1>
                </div>
            </div>
        </div>
    );
};

export default MarketPage;
