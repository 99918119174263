import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import LottieData from '../../assets/lottie/loader/data.json';
import './Loader.scss';

const Loader = () => {
    const lottieRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (lottieRef.current) {
            Lottie.loadAnimation({
                loop: true,
                autoplay: true,
                container: lottieRef.current,
                animationData: LottieData
            });
        }
    }, [lottieRef.current]);
    return (
        <div className="Loader" data-testid="Loader">
            <div ref={lottieRef} className="lottie-container"></div>
        </div>
    );
};

export default Loader;
