import React from 'react';
import './LottieElement.scss';
import Lottie from 'lottie-web';
import { useRef } from 'react';
import { useEffect } from 'react';

interface IlottieElementProps {
    lottieData: any;
    bgImage: string;
    index: number;
}

const LottieElement = ({ lottieData, bgImage, index }: IlottieElementProps) => {
    const lottieRef = useRef<HTMLDivElement>(null);

    const setClass = (index: number) => {
        switch (index) {
            case 0:
                return 'lottie trade';
            case 1:
                return 'lottie time';
            case 2:
                return 'lottie invest';
            default:
                return 'lottie';
        }
    };

    useEffect(() => {
        if (lottieRef.current) {
            Lottie.loadAnimation({
                container: lottieRef.current,
                animationData: lottieData,
                loop: true,
                autoplay: true
            });
        }
    }, [lottieRef, lottieData]);

    return (
        <div
            style={{ backgroundImage: `url(${bgImage})` }}
            className="LottieElement"
            data-testid="LottieElement">
            <div className={setClass(index)} ref={lottieRef}></div>
        </div>
    );
};

export default LottieElement;
