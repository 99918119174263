import React from 'react';
import { string } from 'yup';

export const setHome = (lang: string) => {
    switch (lang) {
        case 'tr':
            return 'Anasayfa';
        case 'en':
            return 'Home';
        case 'de':
            return 'Startseite';

        default:
            return 'Home';
    }
};
export const setAbout = (lang: string) => {
    switch (lang) {
        case 'tr':
            return 'Hakkımızda';
        case 'en':
            return 'About';
        case 'de':
            return 'Über Uns';

        default:
            return 'About';
    }
};
export const setContact = (lang: string) => {
    switch (lang) {
        case 'tr':
            return 'İletişim';
        case 'en':
            return 'Contact';
        case 'de':
            return 'Kontakt';

        default:
            return 'Contact';
    }
};

export const setDownload = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'Download';
        case 'tr':
            return 'İndir';
        case 'de':
            return 'Herunterladen';

        default:
            return 'Download';
    }
};

export const setNewsletterPlaceholder = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'Your email';
        case 'tr':
            return 'E-posta Adresiniz';
        case 'de':
            return 'Email Addresse';

        default:
            return 'Your email';
    }
};
