import React from 'react';
import './ComingSoon.scss';
import Stars from '../../assets/svg/stars.svg';
import Coming from '../../assets/png/download-visual3.png';
import { useLanguage } from '../../store/store';
import { setParag, setStickerText, setTitle } from './utils';

const ComingSoon = () => {
    const { selectedLanguage } = useLanguage();
    return (
        <div className="ComingSoon" data-testid="ComingSoon">
            <div className="ComingSoon__content">
                <div className="ComingSoon__content__sticker">
                    <span className="mr-4">
                        <img src={Stars} alt="" />
                    </span>
                    <span className="font-bold text-blue1">{setStickerText(selectedLanguage)}</span>
                </div>
                <div className="ComingSoon__content__title">{setTitle(selectedLanguage)}</div>
                <div className="ComingSoon__content__parag">{setParag(selectedLanguage)}</div>
            </div>
            <div className="ComingSoon__visual">
                <div className="flex justify-center items-end">
                    <img src={Coming} alt="comingSoon" />
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
