import React, { useEffect } from 'react';
import './TradePage.scss';
import { useLocation } from 'react-router-dom';

const TradePage = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('TradePage is loaded');
        return () => {
            console.log('TradePage is unloaded');
        };
    }, [location]);

    return (
        <div className="TradePage" data-testid="TradePage">
            <div className="relative bg-white">
                <div className="">
                    <h1>TradePage Page</h1>
                </div>
            </div>
        </div>
    );
};

export default TradePage;
