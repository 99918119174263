import React, { useLayoutEffect, useRef } from 'react';
import './Newsletter.scss';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useLanguage } from '../../store/store';
import { setNewsletter } from '../Footer/utils';
import { setNewsletterPlaceholder } from '../Navigation/utils';
const url =
    'https://wunder.us14.list-manage.com/subscribe/post?u=0a9be7a9a941ec37e45cedb9a&id=7dd54f1001';

const Newsletter = () => {
    const newsRef = useRef<HTMLDivElement>(null);
    const { selectedLanguage } = useLanguage();
    const updatePlaceholder = () => {
        const inputs = document.getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            const element = inputs[index];
            if (element.placeholder === 'Your email') {
                return (element.placeholder = setNewsletterPlaceholder(selectedLanguage));
            } else if (element.placeholder === 'E-posta Adresiniz') {
                return (element.placeholder = setNewsletterPlaceholder(selectedLanguage));
            } else if (element.placeholder === 'Email Addresse') {
                return (element.placeholder = setNewsletterPlaceholder(selectedLanguage));
            }
        }
    };
    useLayoutEffect(() => {
        if (newsRef.current !== null && newsRef.current !== undefined) {
            const wrapper = newsRef.current.lastElementChild;
            if (wrapper !== null && wrapper.childNodes !== null) {
                const children = wrapper.childNodes;
                children[1].textContent = '→';
                updatePlaceholder();
            }
        }
    }, [selectedLanguage]);

    return (
        <div ref={newsRef} className="Newsletter" data-testid="Newsletter">
            <label className="font-bold" htmlFor="newsletter">
                {setNewsletter(selectedLanguage)}
            </label>
            <MailchimpSubscribe url={url} />
        </div>
    );
};

export default Newsletter;
