import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
export type TLanguageOption = 'en' | 'de' | 'tr';
type TAvailableLangauges = any;

type TLanguage = {
    selectedLanguage: string;
    setLanguage: (preferedLanguage: string) => void;
};

export const useLanguage = create<TLanguage>(
    persist(
        (set) => ({
            selectedLanguage: 'en',
            setLanguage: (prf: string) => set({ selectedLanguage: prf })
        }),
        {
            name: 'food-storage', // unique name
            getStorage: () => sessionStorage // (optional) by default, 'localStorage' is used
        }
    )
);
