import React, { useEffect } from 'react';
import './WelcomePage.scss';
import { Link, useLocation } from 'react-router-dom';
import Wave from '../../assets/svg/Wave.svg';
import Logo from '../../assets/svg/Logo.svg';
import Time from '../../assets/svg/time.svg';
import ClockData from '../../assets/lottie/clock/data.json';
import LottieElement from '../../components/LottieElement/LottieElement';

const WelcomePage = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('WelcomePage is loaded');
        return () => {
            console.log('WelcomePage is unloaded');
        };
    }, [location]);

    return (
        <div className="WelcomePage" data-testid="WelcomePage">
            <div className="logo">
                <img src={Logo} alt="ElefantLogo" />
            </div>
            <div className="content">
                <div className="time">
                    {/* <img src={Time} alt="clock"></img> */}
                    <LottieElement lottieData={ClockData} bgImage="" index={10}></LottieElement>
                </div>
                <div className="text">
                    <p className=" font-semibold">
                        Thank you for <span className="text-blue1">joining</span> our waitlist.
                    </p>
                </div>
                <div className="link">
                    <Link to="/">
                        <button className="cursor-pointer relative z-50">Back To Website</button>
                    </Link>
                </div>
            </div>
            <div className="footer">
                <div className="footer__logo">
                    <img src={Logo} alt="ElefantLogo" />
                </div>
                <div className="footer__trademark">
                    <p>©2022 Elefant. All Rights Reserved.</p>
                </div>
            </div>
            <div className="wave">
                <img src={Wave} alt="wave" />
            </div>
        </div>
    );
};

export default WelcomePage;
