import React, { useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

interface IAnimatedContentProps {
    title?: string | JSX.Element;
    parag?: string | JSX.Element;
    type: 'parag' | 'title';
}
const setTitle = (title: string, width: number) => {
    const titleWords = title.split(' ');

    if (titleWords.length === 6) {
        if (width >= 1024) {
            return (
                <div>
                    <p>{`${titleWords[0]} ${titleWords[1]} ${titleWords[2]}`}</p>
                    <p>{`${titleWords[3]} ${titleWords[4]} ${titleWords[5]}`}</p>
                </div>
            );
        } else {
            return title;
        }
    } else if (titleWords.length === 4) {
        return (
            <div>
                <p>{`${titleWords[0]} ${titleWords[1]}`}</p>
                <p>
                    {`${titleWords[2]} `}
                    <span className="text-blue1">{`${titleWords[3]}`}</span>
                </p>
            </div>
        );
    } else if (titleWords.length === 2) {
        return (
            <div>
                <p>
                    {`${titleWords[0]} `}
                    <span className="text-blue1">{`${titleWords[1]}`}</span>{' '}
                </p>
            </div>
        );
    } else if (titleWords.length === 11) {
        return (
            <div>
                <p className="text-blue1 whitespace-nowrap">{`${titleWords[0]} ${titleWords[1]} ${titleWords[2]} ${titleWords[3]}`}</p>
                <p>{`${titleWords[5]} ${titleWords[6]} ${titleWords[7]} ${titleWords[8]}`}</p>
                <p>{`${titleWords[9]} ${titleWords[10]} `}</p>
            </div>
        );
    } else if (titleWords.length === 9) {
        return (
            <div>
                <p className="text-blue1 whitespace-nowrap">{`${titleWords[0]} ${titleWords[1]} ${titleWords[2]} ${titleWords[3]}`}</p>
                <p>{`${titleWords[4]} ${titleWords[5]} ${titleWords[6]} ${titleWords[7]} ${titleWords[8]}`}</p>
            </div>
        );
    } else {
        if (width >= 1024) {
            return (
                <div>
                    <p>{`${titleWords[0]} ${titleWords[1]} `}</p>
                    <p>{`${titleWords[2]} ${titleWords[3]} ${titleWords[4]} ${titleWords[5]} ${titleWords[6]}`}</p>
                </div>
            );
        } else {
            return title;
        }
    }
};

const AnimatedContent = ({ title, parag, type }: IAnimatedContentProps) => {
    const { width } = useWindowSize();
    const { ref, inView } = useInView();
    const animation = useAnimation();
    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    type: 'tween',
                    duration: 0.8,
                    delay: 0.2
                }
            });
        }
        if (!inView) {
            animation.start({
                y: 200,
                opacity: 0,
                transition: {
                    type: 'tween',
                    duration: 0.8
                }
            });
        }
    }, [inView]);
    return (
        <>
            {screen.width >= 1024 ? (
                <>
                    {type === 'title' && title && (
                        <motion.h3 ref={ref} animate={animation}>
                            {title}
                        </motion.h3>
                    )}
                </>
            ) : (
                <>{type === 'title' && title && <h3 ref={ref}>{title}</h3>}</>
            )}
            {screen.width >= 1024 ? (
                <>
                    {type === 'parag' && parag && (
                        <motion.p ref={ref} animate={animation}>
                            {parag}
                        </motion.p>
                    )}
                </>
            ) : (
                <>{type === 'parag' && parag && <p ref={ref}>{parag}</p>}</>
            )}
        </>
    );
};

export default AnimatedContent;
