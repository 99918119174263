import React, { useEffect } from 'react';
import './Detay.scss';
import useWindowSize from '../../hooks/useWindowSize';
import AnimatedContent from './AnimatedContent';
import ConvertLottie from '../../assets/lottie/convert/data2.json';
import InvestLottie from '../../assets/lottie/invest/data2.json';
import TimeLottie from '../../assets/lottie/time/data2.json';
import LottieElement from '../LottieElement/LottieElement';
import Phone1 from '../../assets/svg/phone_1.svg';
import Phone2 from '../../assets/svg/phone_2.svg';
import insight from '../../assets/png/insights.png';
import { useLanguage } from '../../store/store';
import { setParag1, setParag2, setTitle1, setTitle2, setTitle3, setParag3 } from './utils';
import PortfolioData3 from '../../assets/lottie/release/data.json';

interface IBlock {
    title: string | JSX.Element;
    parag: string | JSX.Element;
    image: string;
    type: 'image-first' | 'content-first';
    lottieData: any;
}

const Detay = () => {
    const { width } = useWindowSize();
    const { selectedLanguage } = useLanguage();
    useEffect(() => {
        console.log('window width=>', width);
    });
    const blocksNew: IBlock[] = [
        {
            title: setTitle1(selectedLanguage),
            parag: setParag1(selectedLanguage),
            image: insight,
            type: 'image-first',
            lottieData: PortfolioData3
        }
    ];
    const blocks: IBlock[] = [
        {
            title: setTitle1(selectedLanguage),
            parag: setParag1(selectedLanguage),
            image: Phone2,
            type: 'image-first',
            lottieData: ConvertLottie
        },
        {
            title: setTitle2(selectedLanguage),
            parag: setParag2(selectedLanguage),
            image: Phone1,
            type: 'content-first',
            lottieData: TimeLottie
        },
        {
            title: setTitle3(selectedLanguage),
            parag: setParag3(selectedLanguage),
            image: Phone2,
            type: 'image-first',
            lottieData: InvestLottie
        }
    ];

    return (
        <div className="flex flex-col w-full Detay" data-testid="Detay">
            {blocksNew.map((block, index) => {
                return (
                    <div key={index} className={`block ${block.type}`}>
                        <div className="flex mx-auto">
                            <div className="flex relative w-full lg:w-1/2 h-full 2xl:min-h-0 image">
                                <LottieElement
                                    index={index}
                                    bgImage={''}
                                    lottieData={block.lottieData}></LottieElement>
                                {/*   <img src={block.image} alt="" /> */}
                            </div>
                            <div className="content">
                                <AnimatedContent type="title" title={block.title}></AnimatedContent>
                                <AnimatedContent type="parag" parag={block.parag}></AnimatedContent>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Detay;
