import React from 'react';

export const setHeader = (lang: string) => {
    switch (lang) {
        case 'en1':
            return (
                <div>
                    <span>Stocks, Metals, Crypto, NFT’s.</span>
                    <p>
                        <span>Invest In All with Elefant.</span>
                    </p>
                </div>
            );
        case 'en':
            return (
                <div>
                    <span>Design and Run Your Own </span>
                    <p>
                        <span>Investment Scenarios.</span>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div>
                    <span>Yatırım Senaryolarınızı</span>{' '}
                    <p>
                        <span>Oluşturun ve Test Edin.</span>
                    </p>
                </div>
            );
        case 'de':
            return (
                <div>
                    <span>Testen Sie Ihre Eigene</span>
                    <p>
                        <span>Invesment Szenarien</span>
                    </p>
                </div>
            );
        case 'tr1':
            return (
                <div>
                    <span>Hisse, Altın, Kripto, NFT.</span>
                    <p>
                        <span>Elefant ile Hepsine Yatırım Yapın. </span>
                    </p>
                </div>
            );
        case 'de1':
            return (
                <div>
                    <span>Aktien, Metalle, Krypto, NFTs.</span>
                    <p>
                        <span>Investieren Sie In Allem mit Elefant.</span>
                    </p>
                </div>
            );
        default:
            break;
    }
};
export const setParag = (lang: string) => {
    switch (lang) {
        case 'en1':
            return (
                <div className="Hero__content__parag">
                    <p className="whitespace-nowrap">
                        Your portfolio can have <span className="font-bold">both traditional</span>{' '}
                        (Stocks, Metals) and
                    </p>
                    <p>
                        <span className="font-bold">new generation</span> investing instruments
                        (Bitcoin, Ethereum) <span className="font-bold">together.</span>
                    </p>
                </div>
            );
        case 'en':
            return (
                <div className="Hero__content__parag">
                    <p className="whitespace-nowrap">
                        Create your dream portfolio using traditional
                    </p>
                    <p className="whitespace-nowrap">
                        instruments like <span className="font-bold">Stocks and Metals</span> plus
                    </p>
                    <p>
                        <span className="font-bold"> Cryptocurrencies</span> and
                        <span> calculate your potential gains. </span>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div className="Hero__content__parag">
                    <p className="whitespace-nowrap">
                        Hisse Senetleri, altın gibi klasik yatırım araçları ve
                    </p>
                    <p className="whitespace-nowrap">
                        kriptoparaları kullanarak hayalinizdeki portföyü
                    </p>
                    <p>
                        <span>oluşturun, potansiyel kazançlarınızı görüntüleyin. </span>
                    </p>
                </div>
            );
        case 'de':
            return (
                <div className="Hero__content__parag">
                    <p className="whitespace-nowrap">
                        Erstellen Sie Ihr Traumportfolio mit klassischen
                    </p>
                    <p className="whitespace-nowrap">
                        Instrumente wie <span className="font-bold">Aktien und Gold</span>, plus
                    </p>
                    <p>
                        <span className="font-bold">Kriptowaehrungen</span> und finden Sie heraus{' '}
                    </p>
                    <p>wieviel Sie verdienen würden.</p>
                </div>
            );
        default:
            break;
    }
};
export const setParagMobile = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div className="Hero__content__parag-mobile">
                    <p>
                        Your portfolio can have <span className="font-bold">both traditional </span>
                        (Stocks, Metals) and <span className="font-bold">new generation </span>
                        investing instruments (Bitcoin, Ethereum)
                        <span className="font-bold"> together.</span>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div className="Hero__content__parag-mobile">
                    <p>
                        Portfoyünüzü hisse senedi, altın vb.{' '}
                        <span className="font-bold">klasik yatırım</span> araçları ve Bitcoin,
                        Ethereum gibi{' '}
                        <span className="font-bold">yeni nesil yatırım araçlarını</span> beraber
                        kullanarak oluşturun.{' '}
                    </p>
                </div>
            );
        case 'de':
            return (
                <div className="Hero__content__parag-mobile">
                    <p>
                        Ihr Portfolio kann sowohl <span className="font-bold">traditionelle</span>{' '}
                        (Aktien, Metalle) als auch Anlageinstrumente der neuen Generation (Bitcoin,
                        Ethereum) enthalten.
                    </p>
                </div>
            );
        default:
            break;
    }
};
