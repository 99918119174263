import React from 'react';

export const setCompany = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'Company';
        case 'de':
            return 'Firma';
        case 'tr':
            return 'Şirket';
        default:
            return 'Company';
    }
};
export const setSocial = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'Social';
        case 'de':
            return 'Soziale Netzwerke';
        case 'tr':
            return 'Sosyal Medya';
        default:
            return 'Social';
    }
};

export const setNewsletter = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'Newsletter';
        case 'de':
            return 'Newsletter';
        case 'tr':
            return 'Haber Bülteni';
        default:
            break;
    }
};
export const setTradeMark = (lang: string) => {
    switch (lang) {
        case 'en':
            return '©2022 Elefant. All Rights Reserved.';
        case 'de':
            return '©2022 Elefant. All Rechte vorbehalten.';
        case 'tr':
            return '©2022 Elefant. Tüm hakları saklıdır.';
        default:
            return '©2022 Elefant. All Rights Reserved.';
    }
};
