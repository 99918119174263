import React, { useEffect } from 'react';
import './AcademyPage.scss';
import { useLocation } from 'react-router-dom';

const AcademyPage = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('AcademyPage is loaded');
        return () => {
            console.log('AcademyPage is unloaded');
        };
    }, [location]);

    return (
        <div className="AcademyPage" data-testid="AcademyPage">
            <div className="relative bg-white">
                <div className="">
                    <h1>AcademyPage Page</h1>
                </div>
            </div>
        </div>
    );
};

export default AcademyPage;
