import React from 'react';

export const setTitle1 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div>
                    <p>Get Real-Time</p>
                    <p>
                        <span className="text-blue1">Market Insights</span>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div>
                    <p>Uygulanabilir</p>
                    <p>
                        <span className="text-blue1">Yatırım İpuçları</span>
                    </p>
                </div>
            );
        case 'de':
            return (
                <div>
                    <p>Umsetzbare</p>
                    <p>
                        <span className="text-blue1">Handelshinweise</span>
                    </p>
                </div>
            );
        case 'en1':
            return (
                <div>
                    <p>Exchange Anything</p>
                    <p>
                        To <span className="text-blue1">Anything</span>
                    </p>
                </div>
            );
        case 'tr1':
            return (
                <div>
                    <p>İstediğiniz Yatırım Aracını</p>
                    <p>İstediğinize Dönüştürün</p>
                </div>
            );
        default:
            return (
                <div>
                    <p>Alles gegen</p>
                    <p>Alles tauschen</p>
                </div>
            );
    }
};
export const setTitle2 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div>
                    <p>
                        Trade <span className="text-blue1">7/24.</span>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div>
                    <p>
                        <span className="text-blue1">7/24</span> İşlem Yapın
                    </p>
                </div>
            );
        case 'de':
            return (
                <div>
                    <p>
                        <span className="text-blue1">7/24</span> Handeln
                    </p>
                </div>
            );
        default:
            return (
                <div>
                    <p>
                        Trade <span className="text-blue1">7/24.</span>
                    </p>
                </div>
            );
    }
};

export const setTitle3 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div>
                    <p>Start Your Portfolio</p>
                    <p>
                        Even With <span className="text-blue1">1$</span>
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div>
                    <p>Yatırım Yapmaya</p>
                    <p>
                        <span className="text-blue1">1$ </span> İle Başlayın
                    </p>
                </div>
            );
        case 'de':
            return (
                <div>
                    <p>Starten Sie Ihr </p>
                    <p>
                        Portfolio mit <span className="text-blue1"> 1$</span>
                    </p>
                </div>
            );
        default:
            return (
                <div>
                    <p>Start Your Portfolio</p>
                    <p>
                        Even With <span className="text-blue1">1$</span>
                    </p>
                </div>
            );
    }
};
export const setParag1 = (lang: string) => {
    switch (lang) {
        case 'en1':
            return (
                <div>
                    You can <span className="font-bold">sell Gold</span> and{' '}
                    <span className="font-bold">buy Bitcoin</span>, or sell Ethereum and buy Tesla
                    stocks. You can literally exchange anything with{' '}
                    <span className="font-bold">zero commissions.</span>
                </div>
            );
        case 'tr1':
            return (
                <div>
                    Elefant ile porfoyünüzde bulunan
                    <span className="font-bold"> yatırım araçlarını </span>
                    dilediğiniz gibi <span className="font-bold">birbirine dönüştürebilir</span>, ve
                    bu işlemler için komisyon ücreti ödemezsiniz.
                </div>
            );
        case 'de1':
            return (
                <div>
                    Sie können <span className="font-bold"> Gold </span> verkaufen und{' '}
                    <span className="font-bold"> Bitcoin </span> kaufen oder Ethereum verkaufen und
                    <span className="font-bold"> Tesla</span>-Aktien kaufen. Sie können alles in
                    Ihrem Portfolio <span className="font-bold">ohne Provisionen</span> umtauschen.
                </div>
            );
        case 'en':
            return (
                <div>
                    Get <span className="font-bold">timely notifications</span> about market
                    movements with actionable insights based on historical data and fundamental
                    analysis
                </div>
            );
        case 'tr':
            return (
                <div>
                    Pazar hareketleri, temel analiz ve tarihsel verilere dayanan yatırım ipuçları
                    cep telefonunuza gelsin.{' '}
                </div>
            );
        case 'de':
            return (
                <div>
                    Erhalten Sie Mitteilungen über interessante Marktbewegungen und umsetzbare
                    Handelshinweise die auf historischen Daten und Fundamentalanalysen basieren.
                </div>
            );
        default:
            return (
                <div>
                    You can <span className="font-bold">sell Gold</span> and{' '}
                    <span className="font-bold">buy Bitcoin</span>, or sell Ethereum and buy Tesla
                    stocks. You can literally exchange anything with{' '}
                    <span className="font-bold">zero commissions.</span>
                </div>
            );
    }
};
export const setParag2 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div>
                    Markets shut down, but the Internet does not. Therefore, Elefant’s trading
                    services are <span className="font-bold">accessible on 7/24.</span>
                </div>
            );
        case 'tr':
            return (
                <div>
                    Piyasalar kapanır ama Internet devam eder. Elefant’ın yatırım servisleri{' '}
                    <span className="font-bold"> 7/24 işleme açıktır. </span> Örneğin bir pazar
                    gecesi elinizdeki Tesla hisselerini Ethereum’a dönüştürebilirsiniz.
                </div>
            );
        case 'de':
            return (
                <div>
                    Die Märkte werden geschlossen, das Internet jedoch nicht. Daher sind die
                    Handelsdienste von Elefant 7/24 zugänglich.
                </div>
            );
        default:
            return (
                <div>
                    Markets shut down, but the Internet does not. Therefore, Elefant’s trading
                    services are <span className="font-bold">accessible on 7/24.</span>
                </div>
            );
    }
};
export const setParag3 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div>
                    At Elefant you can buy{' '}
                    <span className="font-bold"> a fraction of an asset. </span> Let’s say the stock
                    price of a Fortuna 100 company is 100$. Even{' '}
                    <span className="font-bold"> with just 1$ you can purchase 0,01 shares </span>{' '}
                    and start building your portfolio today.
                </div>
            );
        case 'tr':
            return (
                <div>
                    <span className="font-bold">Parçalı alım </span> yaklaşımı ile istediğiniz
                    tutarda yatırım yapmaya başlayabilir,{' '}
                    <span className="font-bold"> 1$ ile 100$ lık bir hissenin yüzde birine </span>
                    yatırım yaparak portfoyünüzü oluşturmaya bugün başlayabilirsiniz.
                </div>
            );
        case 'de':
            return (
                <div>
                    Bei Elefant können Sie einen Bruchteil eines Aktens kaufen. Nehmen wir an, der
                    Aktienkurs eines Fortuna 100-Unternehmens beträgt 100 $. Schon mit nur 1$ können
                    Sie 0,01 Aktien kaufen und noch heute damit beginnen, Ihr Portfolio aufzubauen.
                </div>
            );
        default:
            return (
                <div>
                    At Elefant you can buy{' '}
                    <span className="font-bold"> a fraction of an asset. </span> Let’s say the stock
                    price of a Fortuna 100 company is 100$. Even{' '}
                    <span className="font-bold"> with just 1$ you can purchase 0,01 shares </span>{' '}
                    and start building your portfolio today.
                </div>
            );
    }
};
