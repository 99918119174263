import React, { useEffect, useRef } from 'react';
import './Hero.scss';
import Qr from '../../assets/png/qrcode_elefant.png';
import Mouse from '../../assets/svg/mouse.svg';
import Arrow from '../../assets/svg/arrow-down.svg';
import Wing from '../../assets/png/wing.png';
import PortfolioData3 from '../../assets/lottie/hero/data.json';
import Lottie from 'lottie-web';
import { useLanguage } from '../../store/store';
import { setHeader, setParag, setParagMobile } from './utils';

const Hero = () => {
    const lottieRef = useRef<HTMLDivElement>(null);
    const { selectedLanguage } = useLanguage();
    useEffect(() => {
        if (lottieRef.current) {
            Lottie.loadAnimation({
                container: lottieRef.current,
                animationData: PortfolioData3,
                loop: true,
                autoplay: true
            });
        }
    }, [lottieRef]);
    return (
        <div className="Hero" data-testid="Hero">
            <div className="Hero__content">
                <div className="Hero__content__header">{setHeader(selectedLanguage)}</div>
                {setParag(selectedLanguage)}
                {setParagMobile(selectedLanguage)}
                <div className="Hero__content__qr">
                    <img src={Qr} alt="" />
                </div>
            </div>
            <div className="flex w-full lg:w-1/2 relative justify-center items-center Hero__image">
                <img
                    src={Wing}
                    alt="telefonResim"
                    className="h-full absolute top-0 object-contain transform "
                />
                <div className="flex overflow-hidden rounded-3xl w-full justify-center items-center">
                    <div
                        className="flex w-full  overflow-hidden rounded-3xl Hero__lottie"
                        ref={lottieRef}></div>
                </div>
            </div>
            <div className="Hero__scroll">
                <img src={Mouse} alt="" />
                <img className="arrowy" src={Arrow} alt="" />
            </div>
        </div>
    );
};

export default Hero;
