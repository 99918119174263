import React, { useEffect } from 'react';
import './AboutPage.scss';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../utils/utils';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { useLanguage } from '../../store/store';
import { setBrandText, setHead, setList, setTitle, subTitle } from './utils';
import Brand from '../../assets/svg/brand.svg';
import Cloud1 from '../../assets/png/cloud1.png';
import Cloud2 from '../../assets/png/cloud2.png';
const AboutPage = () => {
    const location = useLocation();
    const { selectedLanguage } = useLanguage();

    useEffect(() => {
        console.log('AboutPage is loaded');
        return () => {
            console.log('AboutPage is unloaded');
        };
    }, [location]);

    return (
        <motion.div
            transition={pageTransitions}
            variants={pageVariants}
            animate="enter"
            exit="exit"
            initial="initial"
            className="AboutPage"
            data-testid="AboutPage">
            <div className="AboutPage__frame">
                <div className="AboutPage__frame__main">
                    <div className="AboutPage__frame__main__title">
                        {setTitle(selectedLanguage)}
                    </div>
                    <div className="AboutPage__frame__main__parag">
                        {subTitle(selectedLanguage)}
                    </div>
                    <div className="AboutPage__frame__main__list">
                        <div className="AboutPage__frame__main__list__head">
                            {setHead(selectedLanguage)}
                        </div>
                        <ul className="AboutPage__frame__main__list__con">
                            {setList(selectedLanguage).map((item, ind) => {
                                return (
                                    <li key={ind}>
                                        <span className="circle"></span>
                                        <span>{item}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="AboutPage__frame__brand">
                    <div className="AboutPage__frame__brand__logo">
                        <img src={Brand} alt="wunder"></img>
                    </div>
                    <div className="AboutPage__frame__brand__text">
                        {setBrandText(selectedLanguage)}
                    </div>
                </div>
                <img className="cloud1" src={Cloud1} alt="" />
                <img className="cloud2" src={Cloud2} alt="" />
            </div>
            <Footer label="blue"></Footer>
        </motion.div>
    );
};

export default AboutPage;
