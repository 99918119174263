import React from 'react';
import './StoreBtn.scss';
import Apple from '../../assets/svg/apple.svg';
import Google from '../../assets/svg/google.svg';

const StoreBtn = () => {
    return (
        <div className="StoreBtn" data-testid="StoreBtn">
            <span
                onClick={() =>
                    (window.location.href = 'https://apps.apple.com/us/app/elefant/id1614980280')
                }>
                <img src={Apple} alt="appleStoreButonu" />
            </span>
            <span
                onClick={() =>
                    window.open(
                        'https://play.google.com/store/apps/details?id=is.wunder.elefant',
                        '_top'
                    )
                }>
                <img src={Google} alt="googleStoreButonu" />
            </span>
        </div>
    );
};

export default StoreBtn;
