import React from 'react';
import { TLanguageOption } from '../../store/store';

export const setTitle = (lang: string) => {
    switch (lang) {
        case 'tr':
            return (
                <div className="DownloadPage__frame__content__title">
                    <h2>Design and Run Your Own</h2>
                    <h2>Investment Scenarios</h2>
                </div>
            );
        case 'de':
            return (
                <div className="DownloadPage__frame__content__title">
                    <h2>Design and Run Your Own</h2>
                    <h2>Investment Scenarios</h2>
                </div>
            );
        case 'en':
            return (
                <div className="DownloadPage__frame__content__title">
                    <h2>Design and Run Your Own</h2>
                    <h2>Investment Scenarios</h2>
                </div>
            );
        default:
            break;
    }
};
export const setText = (lang: string) => {
    switch (lang) {
        case 'tr':
            return (
                <div className="DownloadPage__frame__content__links__store__info">
                    <p>QR Kodu Okutarak Mobil</p>
                    <p>Uygulamayı İndirin</p>
                </div>
            );
        case 'en':
            return (
                <div className="DownloadPage__frame__content__links__store__info">
                    <p>Download Mobile App by</p>
                    <p>Scanning QR Code</p>
                </div>
            );
        case 'de':
            return (
                <div className="DownloadPage__frame__content__links__store__info">
                    <p>Scannen sie die QR-Code um die</p>
                    <p>Mobile App herunterzuladen.</p>
                </div>
            );
        default:
            break;
    }
};
