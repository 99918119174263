import React from 'react';
import './Footer.scss';
import Logo from '../../assets/svg/Logo.svg';
import LogoWhite from '../../assets/svg/Logo-white.svg';
import StoreBtn from '../StoreBtn/StoreBtn';
import Newsletter from '../Newsletter/Newsletter';
import { setCompany, setSocial, setTradeMark } from './utils';
import { useLanguage } from '../../store/store';
import { setAbout, setContact, setHome } from '../Navigation/utils';
interface IFooterProps {
    label: 'white' | 'blue';
}

const Footer = ({ label }: IFooterProps) => {
    const { selectedLanguage } = useLanguage();
    return (
        <div className={`Footer ${label}`} data-testid="Footer">
            <div className="Footer__logo">
                <div className="flex w-20">
                    <img src={label === 'white' ? Logo : LogoWhite} alt="logo"></img>
                </div>
                <StoreBtn></StoreBtn>
            </div>
            <div className="Footer__links">
                <div className="Footer__links__company">
                    <span className="font-bold">{setCompany(selectedLanguage)}</span>
                    <div className="flex flex-col">
                        <a className="font-light" href="/">
                            {setHome(selectedLanguage)}
                        </a>
                        <a className="font-light mt-2" href="/about">
                            {setAbout(selectedLanguage)}
                        </a>
                        <a className="font-light mt-2" href="/contact">
                            {setContact(selectedLanguage)}
                        </a>
                    </div>
                </div>
                <div className="Footer__links__social">
                    <span className="font-bold">{setSocial(selectedLanguage)}</span>
                    <div className="flex">
                        <div className="flex flex-col">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="font-light"
                                href="https://www.instagram.com/elefant.finance/">
                                Instagram
                            </a>
                            <a className="font-light mt-2" href="">
                                Facebook
                            </a>
                            <a
                                className="font-light mt-2"
                                rel="noreferrer"
                                target="_blank"
                                href="https://twitter.com/ElefantFinance">
                                Twitter
                            </a>
                        </div>
                        <div className="flex flex-col ml-4 lg:ml-8">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="font-light"
                                href="https://www.linkedin.com/company/elefant-finance/">
                                Linkedin
                            </a>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="font-light mt-2"
                                href="https://www.youtube.com/channel/UCCO-lCe0IH3umxyL_cP0nIQ">
                                Youtube
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Footer__newsletter">
                <Newsletter></Newsletter>
                <span>{setTradeMark(selectedLanguage)}</span>
            </div>
        </div>
    );
};

export default Footer;
