import React, { useEffect } from 'react';
import './RegisterPage.scss';
import { useLocation } from 'react-router-dom';

const RegisterPage = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('RegisterPage is loaded');
        return () => {
            console.log('RegisterPage is unloaded');
        };
    }, [location]);

    return (
        <div className="RegisterPage" data-testid="RegisterPage">
            <div className="relative bg-white">
                <div className="">
                    <h1>RegisterPage Page</h1>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
