import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './Navigation.scss';
import Logo from '../../assets/svg/Logo.svg';
import Menu from '../../assets/svg/Menu.svg';
import Cross from '../../assets/svg/cross.svg';
import { AnimatePresence, motion } from 'framer-motion';
import StoreBtn from '../StoreBtn/StoreBtn';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { useLanguage } from '../../store/store';
import { setAbout, setContact, setDownload, setHome } from './utils';

const Navigation = () => {
    const history = useHistory();
    const location = useLocation();
    const [path, setPath] = useState<'/about' | '/contact' | '/download' | '/academy' | '/'>('/');
    const [isSlideOpen, setIsSlideOpen] = useState<boolean>(false);
    const { selectedLanguage } = useLanguage();
    useEffect(() => {
        switch (location.pathname) {
            case '/':
                return setPath('/');
            case '/about':
                return setPath('/about');
            case '/contact':
                return setPath('/contact');
            case '/download':
                return setPath('/download');
            case '/academy':
                return setPath('/academy');
            default:
                break;
        }
    }, [location]);

    const linkHandler = (path: string) => {
        setIsSlideOpen(!isSlideOpen);
        history.push(path);
    };
    return (
        <div className={`Navigation ${isSlideOpen ? 'open' : ''}`} data-testid="Navigation">
            <div className="Navigation__logo">
                <Link to="/">
                    <div>
                        <img src={Logo} alt="logo" />
                    </div>
                </Link>
            </div>

            <div className="Navigation__links">
                <ul>
                    <li className={path === '/' ? 'active' : ''}>
                        <Link to="/">{setHome(selectedLanguage)}</Link>
                    </li>
                    <li className={path === '/about' ? 'active' : ''}>
                        <Link to="/about">{setAbout(selectedLanguage)}</Link>
                    </li>
                    <li className={path === '/contact' ? 'active' : ''}>
                        <a href="mailto:info@elefant.finance">{setContact(selectedLanguage)}</a>
                    </li>
                    <li className="download">
                        <Link to="/download">{setDownload(selectedLanguage)}</Link>
                    </li>
                </ul>
                <div className="Navigation__language">
                    <LanguageSwitch></LanguageSwitch>
                </div>
            </div>

            <div className="Navigation__menu" onClick={() => setIsSlideOpen(!isSlideOpen)}>
                <AnimatePresence exitBeforeEnter={true}>
                    {isSlideOpen ? (
                        <motion.img
                            animate={{ opacity: 1 }}
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            src={Cross}
                            alt="close"
                        />
                    ) : (
                        <motion.img
                            animate={{ opacity: 1 }}
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            src={Menu}
                            alt="menuOpen"
                        />
                    )}
                </AnimatePresence>
            </div>

            <AnimatePresence exitBeforeEnter>
                {isSlideOpen && (
                    <motion.div
                        animate={{ x: 0 }}
                        initial={{ x: '100%' }}
                        exit={{
                            x: '100%',
                            transition: {
                                duration: 0.5
                            }
                        }}
                        transition={{ duration: 0.8 }}
                        className={`Navigation__side ${isSlideOpen ? 'open' : ''}`}>
                        <div onClick={() => linkHandler('/')}>
                            <span>Home</span>
                        </div>
                        <div className="about" onClick={() => linkHandler('/about')}>
                            <span>About</span>
                        </div>
                        <a href="mailto:info@elefant.finance">Contact</a>
                        <div className="bottom-link">
                            <StoreBtn></StoreBtn>
                            <LanguageSwitch></LanguageSwitch>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Navigation;
